<template>
  <main class="container mx-auto">
    <section class="section-home">
      <div class="section-home-div w-12x4 h-card">
        <figure class="home-figure">
          <img
            src="https://cabubi.sfo2.digitaloceanspaces.com/brg/img/beatriz_rodriguez_gauna.jpg"
            class="img-retrato u-photo"
            alt="Retrato de Beatriz Rodriguez Gauna"
          />
        </figure>
        <figcaption>
          Retrato de <span class="p-name">Beatriz Rodriguez Gauna</span>
        </figcaption>
      </div>

      <div class="section-home-div w-12x4">
        <article class="home-articulo">
          <header>
            <h2 class="titulo-pagina">
              Ojos al servicio del Alma, ver, sentir y comprender, con el Alma a
              traves de los sentidos.
            </h2>
          </header>
          <p class="h-card parrafo-pagina">
            <span class="p-name">José Gurvich</span>
          </p>
        </article>
      </div>
    </section>
  </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home"
};
</script>
